import React, { useContext, useReducer } from 'react'
import TextField from '@material-ui/core/TextField'
import InputAdornment from '@material-ui/core/InputAdornment'
import Section from 'tcweb-material-components/core/section'
import Button from 'tcweb-material-components/core/button'
import Box from 'tcweb-material-components/core/box'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { makeStyles, useTheme } from '@material-ui/core/styles'
import Skeleton from '@material-ui/lab/Skeleton'
import { useTranslation } from 'react-i18next'
import Typography from '@material-ui/core/Typography'
import parse from 'html-react-parser'
import includes from 'lodash/includes'
import debounce from 'lodash/debounce'
import orderBy from 'lodash/orderBy'
import compact from 'lodash/compact'
import filter from 'lodash/filter'
import reduce from 'lodash/reduce'
import concat from 'lodash/concat'
import remove from 'lodash/remove'
import uniqBy from 'lodash/uniqBy'
import range from 'lodash/range'
import find from 'lodash/find'
import head from 'lodash/head'
import trim from 'lodash/trim'
import map from 'lodash/map'
import get from 'lodash/get'
import Helmet from 'react-helmet'
import { graphql } from 'gatsby'
import classNames from 'classnames'

import LayoutContent from '../components/layout/layoutContent'
import Categories from '../components/merchandise/categories'
import ProductGrid from '../components/merchandise/productGrid'
import Page from '../components/templates/page'
import useLocation from '../hooks/useLocation'
import options from '../templates/components/'
import { decodeText } from '../utilities/helpers'
import Searchable from '../components/common/searchable'
import LocaleContext from '../components/context/localeContext'

const ALL_CATEGORY_SLUG = 'all'
const INITIAL_LOADING_ITEMS = 8
const ITEMS_TO_LOAD = 40
const SPECIAL_CHAR_REGEXP = /[^\w\s\d]/

const useStyles = makeStyles(({ breakpoints, palette, spacing, accessibility: { highContrast } }) => ({
    section: {
        paddingTop: '30px',
        paddingBottom: '30px',
        [breakpoints.up('md')]: {
            paddingTop: '100px',
            paddingBottom: '100px'
        }
    },
    input: {
        display: 'flex',
        width: '100%',
        marginLeft: 'auto',
        marginRight: 'auto',
        [breakpoints.up('md')]: { maxWidth: '50vw' }
    },
    searchTextField: {
        [breakpoints.up('md')]: {
            display: 'flex',
            marginLeft: 'auto',
            marginRight: 'auto',
            width: 'calc(100vw / 3)'
        },
        textTransform: 'uppercase',
        fontWeight: 'bold',
        fontStretch: 'condensed',
        letterSpacing: '.6px'
    },
    searchTextInput: {
        border: `1px solid ${palette.border.primary}`,
        borderRadius: 10,
        backgroundColor: palette.background.default,
        padding: spacing(1),
        '&:hover, &:focus': {
            [highContrast.mediaQuery('active')]: {
                border: `1px solid Highlight`
            }
        }
    },
    searchFocusVisible: {
        [highContrast.mediaQuery('active')]: {
            border: `1px solid Highlight`
        }
    },
    skeletonTabs: {
        [breakpoints.up('md')]: {
            width: '80%',
            marginLeft: 'auto',
            marginRight: 'auto'
        }
    },
    noPostsTitle: {
        textAlign: 'center',
        [breakpoints.up('md')]: {
            width: '60%',
            marginLeft: 'auto',
            marginRight: 'auto'
        }
    }
}))

const orderCategories = (categories) => {
    if (categories.length === 0) return []
    else if (categories.length === 1) return [...categories]
    let allCategory = remove(categories, (category) => category.slug === ALL_CATEGORY_SLUG)
    let orderedCategories = remove(categories, (category) => category.acf !== null && category.acf.order !== undefined && category.acf.order !== null)
    return [...allCategory, ...orderBy(orderedCategories, ['acf.order', 'name'], ['asc', 'asc']), ...orderBy(categories, ['name'], ['asc'])]
}

const transformData = (categories, products, tags) => {
    var searchMap = {}
    var store = { categories: {}, products: {} }

    const _find = (id, items) => {
        if (searchMap[id]) return searchMap[id]

        return find(items, (item) => {
            if (!!item.node) {
                searchMap[item.node.id] = item
                return item.node.id === id
            } else {
                searchMap[item.id] = item
                return item.id === id
            }
        })
    }

    products.forEach(({ node: product }) => {
        let productCategories = reduce(
            product.categories.nodes,
            (result, category) => {
                return category.parent ? result : [...result, _find(category.id, categories)]
            },
            []
        )

        const productSubCategories = reduce(
            product.categories.nodes,
            (result, category) => {
                return category.parent ? [...result, _find(category.id, categories)] : result
            },
            []
        )

        const tagNames = product.tags.nodes.map(tag => (
            tag.name
        ))

        productCategories = uniqBy(productCategories, 'node.id')

        const _product = {
            ...product,
            productCategories,
            productSubCategories,
            queryParams: compact([
                product.title, // title
                ...productCategories.map((c) => c.node.name), // category
                ...productSubCategories.map((sc) => sc.node.name), // sub category
                ...tagNames, // tags
                product.acf.badge // badge
            ])
        }

        product.categories.nodes.forEach((category) => {
            let foundCategory = get(_find(category.id, categories), 'node')
            let parentCategory = foundCategory.parent ? foundCategory.parent.node : undefined

            if (!!parentCategory && store.categories[parentCategory.id]) {
                if (!store.categories[parentCategory.id].subCategories[foundCategory.id]) {
                    store.categories[parentCategory.id].subCategories[foundCategory.id] = {
                        ...foundCategory
                    }
                }
            } else if (!!parentCategory && !store.categories[parentCategory.id]) {
                store.categories[parentCategory.id] = {
                    ...parentCategory,
                    subCategories: { [foundCategory.id]: { ...foundCategory } }
                }
            } else if (!parentCategory && !store.categories[foundCategory.id]) {
                store.categories[foundCategory.id] = {
                    ...foundCategory,
                    subCategories: {}
                }
            }
            store.products[_product.id] = _product
        })
    })

    return {
        ...store,
        products: orderBy(store.products, [(p) => badgeSortMap[p.acf.badge || ''], 'title']),
        categories: orderCategories(
            reduce(
                store.categories,
                (result, item) => {
                    let subCategories = Object.values(item.subCategories)
                    let allSubCategory = {
                        name: ALL_CATEGORY_SLUG,
                        slug: ALL_CATEGORY_SLUG,
                        id: map(item.subCategories, 'id'),
                        products: item.products,
                        parent: item
                    }
                    let orderedItems = orderCategories(subCategories.length > 0 ? concat(subCategories, allSubCategory) : subCategories)
                    if (orderedItems.length > 0) result.push({ ...item, subCategories: orderedItems })
                    else result.push(item)
                    return result
                },
                []
            )
        )
    }
}

const badgeSortMap = {
    new: 1,
    sale: 2,
    limited: 3,
    'pre-order': 4,
    '': 5,
    'sold-out': 6
}

const getTranslation = (t, queryParams) => map(queryParams, (queryParam) => decodeText(queryParam))

const getQueryRegExp = (query) =>
    new RegExp(
        query
            .toLowerCase()
            .replace(SPECIAL_CHAR_REGEXP, '')
            .split(' ')
            .filter(Boolean)
            .map((i) => `(?=.*${i})`)
            .join(''),
        'gm'
    )

const reducer = (state, action) => {
    switch (action.type) {
        case 'set':
            return { ...state, ...action.payload }
        default:
            throw new Error(`Invalid action type passed to reducer: ${action.type}`)
    }
}

const onSearch = (localeContext) => (data, params) => {
    let filteredProducts = [...data]

    if (params.category) {
        filteredProducts = filter(filteredProducts, (item) => filter(item.productCategories, (pc) => pc.node.slug === params.category).length > 0)
    }

    if (params.subCategory && params.subCategory !== ALL_CATEGORY_SLUG) {
        filteredProducts = filter(filteredProducts, (item) => filter(item.productSubCategories, (pc) => pc.node.slug === params.subCategory).length > 0)
    }

    if (trim(params.query)) {
        const query = getQueryRegExp(params.query)
        filteredProducts = filter(filteredProducts, (item) => {
            return getTranslation(localeContext, item.queryParams).join(' ').toLowerCase().replace(SPECIAL_CHAR_REGEXP, '').search(query) > -1
        })
    }

    return filteredProducts
}

const getCategory = (qsCategory, allCategories) => {
    let category = !!qsCategory && find(allCategories, (c) => c.slug === qsCategory)
    if (!category) category = head(allCategories)
    return category
}

const getSubCategory = (qsSubCategory, category) => {
    return !!qsSubCategory ? find(category.subCategories, (sc) => sc.slug === qsSubCategory) : ''
}

const MerchandisePage = (props) => {
    const theme = useTheme()
    const isDesktop = useMediaQuery(theme.breakpoints.up('lg'))
    const { t } = useTranslation()
    const classes = useStyles()
    const { querystring } = useLocation()
    const localeContext = useContext(LocaleContext)

    const { siteData, rawCategories, tags, rawProducts, page } = props.data
    const data = transformData(rawCategories.edges, rawProducts.edges, tags.edges)

    const _category = getCategory(querystring.category || '', data.categories)
    const [state, dispatch] = useReducer(reducer, {
        query: querystring.query || '',
        category: _category,
        subCategory: getSubCategory(querystring.subCategory || '', _category),
        products: !!querystring.query
            ? filter(data.products, (item) => {
                  return getTranslation(t, item.queryParams).join(' ').toLowerCase().replace(SPECIAL_CHAR_REGEXP, '').search(querystring.query) > -1
              })
            : null
    })

    const handleSearchTextChangeWithDebounce = debounce((value) => {
        dispatch({ type: 'set', payload: { query: value } })
    }, 300)

    const handleCategoryChange = (_, category) => {
        !!window &&
            !!window.appInsights &&
            window.appInsights.trackEvent({
                name: 'Merchandise category click',
                properties: {
                    category: category.name,
                    slug: category.slug
                }
            })
        dispatch({ type: 'set', payload: { category, subCategory: head(category.subCategories) } })
    }

    const handleSubCategoryChange = (_, subCategory) => {
        !!window &&
            !!window.appInsights &&
            window.appInsights.trackEvent({
                name: 'Merchandise subcategory click',
                properties: {
                    category: subCategory.parent.name,
                    categorySlug: subCategory.parent.slug,
                    subcategory: subCategory.name,
                    slug: subCategory.slug
                }
            })
        dispatch({ type: 'set', payload: { subCategory } })
    }

    const pageContent = get(page, 'content')

    return (
        <LayoutContent {...props} setBackgroundImage gutters='none'>
            <Helmet title={`${siteData.siteMetadata.title} | ${t('Merchandise')}`} />

            {/* Banner */}
            {!!pageContent && <Page>{parse(pageContent, options)}</Page>}

            <Box gutters='page'>
                <Section className={classes.section}>
                    <TextField
                        id='search-merchandise'
                        label={t('Search merchandise')}
                        className={classes.input}
                        type='search'
                        classes={{ root: classes.searchTextField }}
                        defaultValue={state.query}
                        onChange={(e) => handleSearchTextChangeWithDebounce(e.target.value)}
                        InputProps={{
                            disableUnderline: true,
                            startAdornment: (
                                <InputAdornment position='start'>
                                    <FontAwesomeIcon icon='search' className={classes.searchIcon} />
                                </InputAdornment>
                            ),
                            classes: { root: classes.searchTextInput, focused: classes.searchFocusVisible }
                        }}
                    />

                    {!state.query && (
                        <Categories
                            key='category'
                            selectedCategory={state.category}
                            selectedSubCategory={state.subCategory}
                            allCategories={data.categories}
                            onChange={handleCategoryChange}
                            onSubCategoryChange={handleSubCategoryChange}
                        />
                    )}

                    <Searchable
                        enablePagination
                        page={querystring.page}
                        onSearch={onSearch(localeContext)}
                        perPage={ITEMS_TO_LOAD}
                        params={
                            !!state.query
                                ? { query: state.query }
                                : {
                                      category: state.category.slug,
                                      subCategory: get(state, 'subCategory.slug', '')
                                  }
                        }
                        data={data.products}
                        emptyComponent={
                            !!state.query ? (
                                <Section className={classes.noPostsTitle}>
                                    <Typography variant='h1' classes={{ root: 'h2' }}>
                                        {t('No results found')}
                                    </Typography>
                                    {t('Please check your spelling or try different keywords.')}
                                </Section>
                            ) : (
                                <Typography variant='h2' classes={{ root: classes.noPostsTitle }}>
                                    {t('No items')}
                                </Typography>
                            )
                        }
                        loadingComponent={
                            <Section itemMargins={{ mobile: '20px', desktop: '20px' }}>
                                {range(INITIAL_LOADING_ITEMS).map((i) => (
                                    <div key={`loading-${i}`}>
                                        <Skeleton height='100px' className={classes.skeleton} />
                                        <Skeleton
                                            variant='rect'
                                            height={isDesktop ? '400px' : '200px'}
                                            className={classNames(classes.skeleton, classes.skeletonTabs)}
                                        />
                                        <Skeleton height='50px' width='50%' className={classes.skeleton} />
                                        <Skeleton height='100px' className={classes.skeleton} />
                                        <Skeleton height='50px' width='10%' className={classes.skeleton} />
                                    </div>
                                ))}
                            </Section>
                        }
                        nextPageComponent={
                            <Button classes={{ root: classes.showMoreButton }} variant='contained' color='primary' title={t('Show More')}>
                                {t('Show More')}
                            </Button>
                        }
                    >
                        {({ data }) => <ProductGrid products={data} />}
                    </Searchable>
                </Section>
            </Box>
        </LayoutContent>
    )
}

export const pageQuery = graphql`
    query MerchandiseQuery($cmsLocale: String) {
        siteData: site {
            siteMetadata {
                title
            }
        }
        page: wpSitePage(slug: { eq: "merchandise" }, locale: { locale: {eq: $cmsLocale}}) {
            content
        }
        rawCategories: allWpSiteMerchandiseCategory {
            edges {
                node {
                    id
                    name
                    slug
                    acf: merchandiseCategoryOrderACF {
                        order
                    }
                    parent: wpParent {
                        node {
                            id
                            name
                            slug
                            acf: merchandiseCategoryOrderACF {
                                order
                            }
                        }
                    }
                }
            }
        }
        tags: allWpSiteMerchandiseTag {
            edges {
                node {
                    id
                    name
                    slug
                }
            }
        }
        rawProducts: allWpSiteProduct {
            edges {
                node {
                    id
                    categories: merchandiseCategories {
                        nodes {
                            acf: merchandiseCategoryOrderACF {
                                order
                            }
                            parent: wpParent {
                                node {
                                    id
                                    name
                                    slug
                                }
                            }
                            id
                            name
                        }
                    }
                    tags: merchandiseTags {
                        nodes {
                            parent {
                                id
                            }
                            id
                            name
                        }
                    }
                    title
                    acf: merchandiseProductACF {
                        link
                        badge
                        image: productImage {
                            altText
                            localFile {
                                childImageSharp {
                                    gatsbyImageData(layout: FULL_WIDTH)
                                }
                            }
                        }
                    }
                }
            }
        }
    }
`

export default MerchandisePage
