import React from 'react'
import map from 'lodash/map'
import get from 'lodash/get'
import compact from 'lodash/compact'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import ImageButton from 'tcweb-material-components/core/imageButton'
import Section from 'tcweb-material-components/core/section'
import Badge from 'tcweb-material-components/core/badge'
import Grid from 'tcweb-material-components/core/grid'
import Typography from '@material-ui/core/Typography'
import { makeStyles } from '@material-ui/core/styles'
import { useTheme } from '@material-ui/core/styles'
import { useTranslation } from 'react-i18next'
import classNames from 'classnames'

import { decodeText } from '../../utilities/helpers'
import PlaceholderImg from '../../components/common/placeholderImg'
import Link from '../../components/common/link'

const GRID_COLS = { xs: 2, lg: 4 }

const useStyles = makeStyles(({ breakpoints, gutters }) => ({
    link: {
        maxWidth: 'inherit',
        '& .MuiButton-label': { height: '100%' },
        '&:hover, &:focus': {
            textDecoration: 'none'
        }
    },
    soldOut: {
        '& img': {
            filter: 'brightness(40%)',
        },
        '.image-wrapper&::before': {
            border: 'none !important'
        }
    },
    fullHeight: { height: '100%' },
    imageContainer: {
        display: 'flex',
        flexDirection: 'column',
        position: 'relative'
    },
    img: {
        objectFit: 'contain',
        height: `calc((100vw - (${gutters.page.xs} * 2) - ((${GRID_COLS.xs} - 1) * 30px)) / ${GRID_COLS.xs} )`,

        [breakpoints.up('sm')]: {
            height: `calc((100vw - (${gutters.page.md} * 2) - ((${GRID_COLS.sm} - 1) * 30px)) / ${GRID_COLS.sm} )`
        },
        [breakpoints.up('lg')]: {
            height: `calc((100vw - (${gutters.page.lg} * 2) - ((${GRID_COLS.lg} - 1) * 30px)) / ${GRID_COLS.lg} )`
        },
        background: 'radial-gradient(closest-side, rgb(255, 255, 255, .1), transparent 86%)',
    },
    badge: {
        position: 'absolute',
        content: '" "',
        alignSelf: 'center',
        bottom: 0,
        zIndex: 1,
        fontStretch: 'condensed',
        fontSize: '1rem'
    },
    linkBody: {
        textAlign: 'center',
        padding: '10px',
        [breakpoints.up('md')]: {
            padding: '30px'
        }
    },
    subCategories: {
        textTransform: 'capitalize',
        fontStretch: 'condensed'
    },
    centerText: { textAlign: 'center' }
}))

const GridItem = ({ title, productSubCategories, acf, ...rest }) => {
    const { t } = useTranslation()
    const classes = useStyles()
    const theme = useTheme()
    const subCategoryNames = productSubCategories.map((pc) => pc.node.name)
    const subCategories = subCategoryNames.join(', ')
    const subCategoriesSrOnly = subCategoryNames.length === 1 ? `${t('Subcategory')} ${subCategories}` : `${t('Subcategories')} ${subCategories}`
    const isSoldOut = acf.badge === 'sold-out'

    const ariaLabel = compact([
        !!acf.badge && acf.badge,
        decodeText(title),
        get(acf, 'image.altText', ''),
        subCategoryNames.length > 0 && subCategoriesSrOnly
    ]).join(', ')

    const trackProductClick = () => {
        !!window &&
            !!window.appInsights &&
            window.appInsights.trackEvent({
                name: 'Merchandise product click',
                properties: {
                    title,
                    link: acf.link
                }
            })
    }

    const productChild = () => {
        return (
            <div className={classNames(isSoldOut ? classes.soldOut : null, 'image-wrapper', classes.fullHeight)}>
                <div className={classes.imageContainer}>
                    {get(acf, 'image.localFile.childImageSharp') ? (
                        <GatsbyImage
                            className={classes.img}
                            imgStyle={{ objectFit: 'contain' }}
                            image={getImage(acf.image.localFile)}
                            alt={get(acf, 'image.altText', '')}
                        />
                    ) : (
                        <PlaceholderImg />
                    )}
                    {!!acf.badge && (
                        <Badge classes={{ root: classes.badge }} variant='contained' color='primary'>
                            {!isSoldOut ? acf.badge : 'SOLD OUT'}
                        </Badge>
                    )}
                </div>

                <div className={classes.linkBody}>
                    <Typography color='textPrimary' style={{fontStretch: 'condensed', textTransform: 'uppercase'}}>{decodeText(title)}</Typography>
                    {subCategoryNames.length > 0 && <Typography variant='srOnly'>{subCategoriesSrOnly}</Typography>}
                    <Typography color='textSecondary' aria-hidden='true' classes={{ root: classes.subCategories }}>
                        {subCategories}
                    </Typography>
                </div>
            </div>
        )
    }

    if (isSoldOut) {
        return (
            <div className={classes.link} aria-label={ariaLabel}>
                {productChild()}
            </div>
        )
    }

    return (
        <ImageButton
            component={Link}
            href={acf.link}
            target={'_blank'}
            onClick={trackProductClick}
            classes={{ root: classes.link }}
            aria-label={ariaLabel}
        >
            {productChild()}
        </ImageButton>
    )
}

export default ({ products }) => {
    return <Grid cols={GRID_COLS}>{map(products, (product, i) => !!product && <GridItem {...product} key={i} />)}</Grid>
}