import React from 'react'
import { useTheme, makeStyles } from '@material-ui/core/styles'
import ThemeProvider from 'tcweb-material-components/core/themeProvider'
import find from 'lodash/find'
import Tabs from 'tcweb-material-components/core/tabs'
import Tab from 'tcweb-material-components/core/tab'
import map from 'tcweb-material-components/core/poly/map'
import Section from 'tcweb-material-components/core/section'

import { decodeText } from '../../utilities/helpers'

const useStyles = makeStyles(({ palette }) => ({
    pillTab: {
        '&:hover': {
            backgroundColor: palette.secondary.light,
            color: palette.text.primary
        }
    },
    tabPanel: {
        minHeight: '400px'
    }
}))

const changePrimaryRedTheme = (theme) => ({
    palette: {
      primary: {
          main: theme.palette.text.active
      },
    },
})

const Categories = ({ selectedCategory, selectedSubCategory, allCategories, onChange, onSubCategoryChange, ...rest }) => {
    const theme = useTheme()
    const classes = useStyles()

    return (
        <Section {...rest}>
            <ThemeProvider theme={changePrimaryRedTheme(theme)}>
                <Tabs
                    value={selectedCategory.slug}
                    onChange={(_, slug) =>
                        onChange(
                            _,
                            find(allCategories, (c) => c.slug === slug)
                        )
                    }
                >
                    {map(
                        (category, i) => (
                            <Tab key={`category-${i}`} label={decodeText(category.name)} value={category.slug} />
                        ),
                        allCategories
                    )}
                </Tabs>
            </ThemeProvider>

            {selectedCategory.subCategories.length > 0 && (
                <Tabs
                    value={!!selectedSubCategory ? selectedSubCategory.slug : ''}
                    onChange={(_, slug) =>
                        onSubCategoryChange(
                            _,
                            find(selectedCategory.subCategories, (c) => c.slug === slug)
                        )
                    }
                    indicatorType={'pill'}
                >
                    {map(
                        (subCategory, i) => (
                            <Tab
                                classes={{ root: classes.pillTab }}
                                key={`category-${i}`}
                                label={decodeText(subCategory.name)}
                                value={subCategory.slug}
                            />
                        ),
                        selectedCategory.subCategories
                    )}
                </Tabs>
            )}
        </Section>
    )
}
export default Categories
